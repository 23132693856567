import React from "react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./TokenomicsSection.module.css";
import SliderCard from "../../SliderCard/SliderCard";
import Title from "../../Title/Title";
import cardsData from "/Users/xfear/Desktop/arcadia/src/cardisData";

export default function TokenomicsSection() {
  return (
    <section id="Tokenomics">
      <header className={styles.runningLine}>
        <div className={styles.tickerWrapper}>
          <div className={styles.textWrapper}>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
          </div>
          <div className={styles.textWrapper}>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
            <p>tokenomics in detail</p>
          </div>
        </div>
      </header>
      <div className={styles.tokenWrapper}>
        <Title>tokenomics distribution</Title>
        <h2>Rewards for Players and Developers</h2>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={10}
          pagination={{ clickable: true }}
          breakpoints={{
            360: {
              slidesPerView: 1,
            },
            425: {
              slidesPerView: 2,
            },
            769: {
              slidesPerView: 3,
            },
          }}
        >
          {cardsData.map((card, index) => (
            <SwiperSlide key={index}>
              <SliderCard title={card.title} desc={card.desc} img={card.img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
