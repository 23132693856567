import goldCoin from "/Users/xfear/Desktop/arcadia/src/img/goldcoin.svg";
import Title from "../../Title/Title";
import styles from "./LoyaltySection.module.css";
import loyaltyPhone from "/Users/xfear/Desktop/arcadia/src/img/loyaltyphone.png";
import loyaltyGirls from "/Users/xfear/Desktop/arcadia/src/img/loyaltyGirls.png";
import GlassButton from "../../Buttons/GlassButton";
import system from "/Users/xfear/Desktop/arcadia/src/img/system.png";
export default function LoyaltySection() {
  return (
    <section id="Loyalty">
      <div className={styles.loyaltyWrapper}>
        <div className={styles.loyaltyProgram}>
          <h2>Loyalty Program</h2>
          <div className={styles.loyaltyCard}>
            <Title>Token distribution for players</Title>

            <div className={styles.loyaltyContent}>
              <ul className={styles.loyaltyList}>
                <li>
                  <img src={goldCoin} alt="" />
                  To solve assigned tasks and achievements in the game
                </li>
                <li>
                  <img src={goldCoin} alt="" />
                  To invite new users to the platform
                </li>
                <li>
                  <img src={goldCoin} alt="" />
                  Participation in certain events and tournaments
                </li>
                <li>
                  <img src={goldCoin} alt="" />
                  To provide feedback and participate in beta testing
                </li>
                <li>
                  <img src={goldCoin} alt="" />
                  For activity in the community
                </li>
              </ul>

              <img className={styles.loyaltyPhone} src={loyaltyPhone} alt="" />
              <img className={styles.loyaltyGirls} src={loyaltyGirls} alt="" />
            </div>
            <GlassButton>Claim coins</GlassButton>
          </div>
        </div>

        <div className={styles.developerSystem}>
          <h2>Developer incentive system</h2>
          <div className={styles.systemCard}>
            <div className={styles.systemCardContent}>
              <div className={styles.textContent}>
                <Title>token distribution for developers</Title>

                <ul className={styles.systemList}>
                  <li>
                    <img src={goldCoin} alt="" />
                    Game placement on the platform
                  </li>
                  <li>
                    <img src={goldCoin} alt="" />
                    Promoting the game
                  </li>
                  <li>
                    <img src={goldCoin} alt="" />
                    Attracting users
                  </li>
                  <li>
                    <img src={goldCoin} alt="" />
                    Testing the platform
                  </li>
                </ul>
              </div>
              <img className={styles.systemImg} src={system} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
