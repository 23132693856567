import styles from "./RiskSection.module.css";
import checkbox from "/Users/xfear/Desktop/arcadia/src/img/checkbox.png";

export default function RisksSection() {
  return (
    <section id="Risks">
      <div className={styles.risksWrapper}>
        <h2>Risks and Limitations: How We Overcome Them</h2>
        <div className={styles.waysWrapper}>
          <ul className={styles.waysOfOvercoming}>
            <li>
              <img className={styles.checkbox1} src={checkbox} alt="" />
              <p>
                We use decentralized technologies and distributed servers to
                ensure continuous access to the platform even in case of
                blockages or regional restrictions. Your tokens, games, and
                investments will be protected.
              </p>
            </li>
            <li>
              <img className={styles.checkbox2} src={checkbox} alt="" />
              <p>
                {" "}
                We’ve implemented modern security measures to protect your data
                and transactions, ensuring a high level of privacy and
                protection against cyber threats.
              </p>
            </li>
            <li>
              <img className={styles.checkbox1} src={checkbox} alt="" />
              <p>
                We adapt to changes in cryptocurrency regulation by
                collaborating with jurisdictions worldwide to ensure the
                platform’s compliance with current requirements and maintain
                accessibility.
              </p>
            </li>
            <li>
              <img className={styles.checkbox1} src={checkbox} alt="" />
              <p>
                Developers can use our resources and receive crowdfunding
                support, reducing financial risks. Funds raised through
                crowdfunding are paid out to developers only after the game’s
                release, protecting users from scam projects.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
