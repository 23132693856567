import JoinButton from "../../Buttons/JoinButton";
import styles from "./JoinSection.module.css";
import Title from "../../Title/Title";
import girl from "/Users/xfear/Desktop/arcadia/src/img/girl.png";

export default function JoinSection() {
  return (
    <section id="Join">
      <div className={styles.joinWrapper}>
        <div className={styles.joinInfo}>
          <Title>together towards success</Title>
          <h2>Join the New Gaming Revolution</h2>
          <p>
            Our project is an innovative platform that brings together games and
            cryptocurrency, where you can earn real money just by playing! 🚀
            We’ve launched an application on Telegram and invite you to join our
            rapidly growing community. Don’t miss the chance to be among the
            first to start earning while enjoying games. Join us right now.
          </p>
          <JoinButton />
        </div>
        <img src={girl} alt="JoinGirl" className={styles.joinImg} />
      </div>
    </section>
  );
}
