import styles from "./SuccessSection.module.css";

export default function SuccessSection() {
  return (
    <section id="Success">
      <div className={styles.successWrapper}>
        <h2>Our Path to Success</h2>
        <div className={styles.waysListWrapper}>
          <ul className={styles.waysList}>
            <li>
              <h3>Community Formation</h3>
              <p>
                - Launch of social media
                <br />
                - Launch of the main Telegram app
                <br />- Attracting the first users and developers
              </p>
            </li>
            <li>
              <h3>Advertising Campaign</h3>
              <p>
                - Informing about the benefits and uniqueness of the platform
                <br />- Attracting funds and investors to the project
              </p>
            </li>
            <li>
              <h3>Platform Development</h3>
              <p>
                - Expanding site functionality: user profiles, games,
                crowdfunding
                <br />- Supporting cryptocurrency payments
              </p>
            </li>
            <li>
              <h3>SDK Launch for Developers</h3>
              <p>
                - Development and release of the SDK
                <br />
                - Creation of documentation and video tutorials on how to use
                the SDK
                <br />- Creating feedback chats for developers
              </p>
            </li>
            <li>
              <h3>Pilot Testing</h3>
              <p>
                - Attracting developers to test the SDK
                <br />- Testing the platform’s functionality with community
                participation
              </p>
            </li>
            <li>
              <h3>Token Release and Airdrop</h3>
              <p>
                - Partnering with crypto exchanges
                <br />
                - Token airdrop for the community and developers
                <br />
                - Integrating token exchange and purchase functions within the
                platform <br />- Listing the token on exchanges
              </p>
            </li>
            <li>
              <h3>Platform Launch</h3>
              <p>
                - Opening the platform for public access <br />
                - Launching the crowdfunding function
                <br />- Introducing in-game economy and tokens
              </p>
            </li>
            <li>
              <h3>Marketing and PR</h3>
              <p>
                - Large-scale advertising campaigns to attract users and
                developers
                <br />- Organizing gaming tournaments with rewards for users and
                developers
              </p>
            </li>
            <li>
              <h3>Partnerships with Other Platforms</h3>
              <p>
                - Integrating games with other Web3 platforms
                <br />- Attracting large studios to create exclusive content
              </p>
            </li>
            <li>
              <h3>Platform Improvement</h3>
              <p>
                - Implementing DAO voting for the community
                <br />- Supporting the platform and introducing new features
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
