import Title from "../../Title/Title";
import styles from "./EarnSection.module.css";
import RewardCard from "../../Cards/RewardCard/RewardCard";
import hand from "/Users/xfear/Desktop/arcadia/src/img/hand.png";
import trophy from "/Users/xfear/Desktop/arcadia/src/img/trophy1.png";
export default function EarnSection() {
  return (
    <section id="Rewards">
      <div className={styles.earnWrapper}>
        <div className={styles.earnInfo}>
          <Title>tokenomics</Title>
          <h2>Earn Real Value for Gaming Achievements</h2>
          <p>
            Our token will be used as a medium of exchange and a store of value
            on the platform. It will grant access to games, allow investments in
            games, purchases of in-game items and upgrades, and reward players
            and developers.
          </p>
        </div>
        <div className={styles.rewardCards}>
          <RewardCard
            title="Player rewards"
            desc="Players will earn tokens for their achievements and participation in competitions. This will encourage active participation and reward success in games."
            bg={hand}
          ></RewardCard>
          <RewardCard
            title="Developer rewards"
            desc="Game developers will earn a percentage of the revenue generated by their games. This will encourage the creation 
of high quality and innovative games on our platform."
            bg={trophy}
          ></RewardCard>
        </div>
      </div>
    </section>
  );
}
