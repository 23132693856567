import group from "/Users/xfear/Desktop/arcadia/src/img/group.png";
import Card from "../Cards/Card";
import styles from "./SolutionSection.module.css";
import phone from "/Users/xfear/Desktop/arcadia/src/img/phone.png";
import adventure from "/Users/xfear/Desktop/arcadia/src/img/adventure.png";
import stats from "/Users/xfear/Desktop/arcadia/src/img/stats.svg";
import fund from "/Users/xfear/Desktop/arcadia/src/img/fund.png";

export default function SolutionSection() {
  return (
    <section id="Solution">
      <div className={styles.sectionWrapper}>
        <div className={styles.solutionWrapper}>
          <h2>Why traditional games fall short: the solution is here</h2>
          <p>
            <span style={{ color: "#73777B" }}>
              Modern online games often use unfair monetization models that
              prevent players from gaining real value from their time and
              effort, offering only digital assets with no worth in the real
              world. There is also a lack of trust between players and
              developers, while independent developers have limited
              opportunities to bring their ideas to life.
            </span>
            <br />
            We offer a platform where players can play various games and earn
            tokens with real value. Developers get access to our resources and
            tools to create and publish their games, earning a share of the
            revenue generated by their projects.
          </p>
          <div className={styles.profileGroup}>
            <img src={group} alt="Profile Group" />
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          <Card
            title="web-3 games"
            desc="Our platform features exciting HTML5 games across various genres, interacting with our cryptocurrency. Users can improve their ranking and earn tokens by playing games"
            img={phone}
            buttonText="Claim coins"
          />
          <Card
            title="Publishing Resources"
            desc="Developers can easily integrate our SDK into their projects using a simple step-by-step guide. Simplified game integration allows you to focus on development, not platform adaptation."
            img={adventure}
            buttonText="Start creating"
            buttonStyle={{ bottom: "13.5%" }}
          />
          <Card
            title="Crowdfunding"
            desc="Our platform offers an opportunity to present your idea to the community, raise funds for development, and gather an interested audience even before the release."
            img={fund}
            buttonStyle={{ display: "none" }}
          />
          <Card
            title="High Traffic"
            desc="A large flow of users provides your game with access to an active audience, helping to attract players quickly and increase the profitability of your project. We create conditions for effective growth and visibility of your product."
            img={stats}
            buttonStyle={{ display: "none" }}
          />
        </div>
      </div>
    </section>
  );
}
